.content {
  padding-left: calc(50% - 1000px);
  padding-right: calc(50% - 1000px);
}

.content__box__tittle {
  margin-top: 200px;
  display: flex;
  justify-content: space-between;
}

.content__box__tittle__text {
  font-size: 60pt;
  font-weight: 700;
}

.content__gorline {
  border-top: 5px solid black;
  margin-bottom: 50px;
  margin-top: 20px;
}

.about {
  /* margin-top: 100px; */
}

.about__box {
  display: flex;
  justify-content: space-between;
}

.about__box__texts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
}

.about__box__text {
  text-align: left;
  font-size: 25pt;
  line-height: 40px;
  font-weight: 100;
}

.about__box__text_small {
  font-size: 20pt;
}

.about__box__img {
  width: 855px;
  height: 977px;
}

.service {
  /* margin-top: 100px; */
}

.service__box {
  display: flex;
  justify-content: space-between;
}

.service___box__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}

.service__box__content {
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.service__box__tittle {
  font-size: 25pt;
  font-weight: 100;
}

.service__box__text {
  font-size: 20pt;
  text-align: left;
  width: 797px;
  font-weight: 100;
}

.service__box__text_down {
  margin-top: 30px;
}

.service__box__text_bold {
  font-weight: bold;
}

.service__box__button {
  border-radius: 5px;
  border-color: #aeaeae;
  font-size: 20pt;
  width: 400px;
  color: #aeaeae;
  background: transparent;
  padding: 10px 20px;
  margin-top: 40px;
}

.service__box__button:hover {
  cursor: pointer;
  color: white;
  background: #aeaeae;
  transform: scale(1.05);
}

.service__box__img {
  width: 850px;
  height: 550px;

  margin-top: 0px;
}

/* .projects {
}

.projects__box {
} */

.projects__box_down {
  /* margin-top: 80px; */
}

.projects__box__tittle {
  text-align: left;
  font-size: 25pt;
  margin-bottom: 10px;
  margin-top: 50px;
}

.projects__box__content {
  display: flex;
  gap: 40px;
}

.projects__box__imgbox {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.projects__box__img {
  background-repeat: no-repeat;
  width: 540px;
  height: 370px;
}

/* .projects__box__img_1 {
  background-image: url(/public/img/projectsinterior1.png);
}

.projects__box__img_2 {
  background-image: url(/public/img/projectsinterior2.png);
}

.projects__box__img_3 {
  background-image: url(/public/img/projectsexh1.png);
}

.projects__box__img_4 {
  background-image: url(/public/img/projectsexh2.png);
} */

.projects__box__imgtext {
  font-size: 15pt;
  text-align: left;
}

.projects__box__texts {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: space-between;
}

.projects__box__text {
  font-size: 20pt;
  font-weight: 100;
  text-align: left;
}

.projects__box__button {
  border-radius: 5px;
  font-size: 25px;
  width: 400px;
  color: #aeaeae;
  background: transparent;
  padding: 10px 20px;
  margin-bottom: 25px;
}

.realization {
  margin-top: 100px;
}

.realization__content {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.realization__box {
  display: flex;
  justify-content: space-between;
}

.realization__box__img {
  width: 440px;
  height: 550px;
}

/* .realization__box__img_1 {
  background-image: url(/public/img/realization1.png);
}

.realization__box__img_2 {
  background-image: url(/public/img/realization2.png);
}

.realization__box__img_3 {
  background-image: url(/public/img/realization3.png);
}

.realization__box__img_4 {
  background-image: url(/public/img/realization4.png);
} */

.realization__box__img_big {
  width: 744px;
  height: 550px;
}

/* .realization__box__img_big_1 {
  background-image: url(/public/img/realizationbig1.png);
}

.realization__box__img_big_2 {
  background-image: url(/public/img/realizationbig2.png);
} */

.realization__footer {
  display: flex;
  flex-direction: column;
  align-items: end;
}

/* .realization__button {
}

.wrnt {
} */

.wrnt__tittles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wrnt__tittle {
  font-size: 70pt;
  color: #aeaeae;
  font-weight: 300;
}

.wrnt__box {
  background-repeat: no-repeat;
  width: 100%;
  height: 362px;
}

.wrnt__slogan {
  font-size: 28pt;
  text-align: right;
  margin-top: 50px;
}

.wrnt__laptop {
  margin-top: 50px;
  background-repeat: no-repeat;
  width: 1718px;
  height: 1067px;
  margin-bottom: 100px;
}

.footer {
  background-color: #dedede;
  padding-top: 100px;
  padding-right: 300px;
  padding-left: 300px;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  flex-wrap: wrap;
}

.footer__box__tittle {
  display: flex;
  justify-content: space-between;
}

.footer__box__tittle__text {
  font-size: 35pt;
  font-weight: 500;
}

.footer__box {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: start;
}

.footer__box_right {
  gap: 30px;
}

.footer__box__contacts {
  display: flex;
  gap: 100px;
}

.footer__box__contact {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
}

.footer__box__contact__text {
  text-decoration: none;
  color: black;
  font-size: 35pt;
  font-weight: 200;
}

.footer__logo {
  background-color: #dedede;
  width: 100%;
  height: 330px;
}

.footer__links {
  display: flex;
  gap: 100px;
}

.footer__link {
  text-decoration: none;
}

.footer__link__img {
  height: 80px;
  width: 80px;
}

.footer__button {
  border-radius: 5px;
  background: black;
  color: white;
  font-size: 25pt;
  width: 400px;
  font-weight: 300;
  padding: 20px 20px;
}

.footer__button:hover {
  transform: scale(1.05);
  cursor: pointer;
}
