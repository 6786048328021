.nav__burger {
  width: 30px;
  height: 36px;
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nav__burger {
  cursor: pointer;
}

.burger__line {
  display: inline-block;
  width: 300%;
  height: 5px;
  background-color: white;
  margin-left: 20px;
}

.nav__menu {
  display: block;
  visibility: visible;
  background-color: white;
  width: 15%;
  height: 1210px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.menu__list {
  padding-top: 40px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  list-style-type: none;
}

.menu__item {
  padding: 5px 0;
  margin-bottom: 12px;
}

.menu__item_big {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu__link {
  text-decoration: none;
  color: #777777;
  font-weight: 400;
  font-size: 21pt;
  line-height: 24px;
}

.menu__link:hover {
  color: rgb(99, 99, 99);
}

.menu__link_small {
  text-decoration: none;
  color: #909090;
  font-weight: 400;
  font-size: 15pt;
  line-height: 24px;
}

.menu__link_small:hover {
  color: rgb(99, 99, 99);
}

.menu__link__contact {
  text-decoration: none;
  color: #909090;
  font-weight: 400;
  font-size: 18pt;
}

.menu__contact {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.menu__contact__link {
  text-align: left;
  text-decoration: none;
  color: #909090;
  font-weight: 400;
  font-size: 18pt;
}

.menu__contact__button {
  border-radius: 5px;
  border-color: #aeaeae;
  width: 220px;
  font-size: 18pt;
  text-align-last: left;
  color: #909090;
  padding: 8px 10px;
}

.menu__contact__button:hover {
  color: white;
  background: #aeaeae;
  transform: scale(1.05);
  cursor: pointer;
}

@media (max-width: 1920px) {
  .burger__line {
    width: 200%;
    height: 3px;
  }

  .nav__menu {
    padding: 0px;
    width: 15%;
    height: 700px;
  }

  .menu__list {
    gap: 20px;
  }

  .menu__item {
    margin-bottom: 0px;
  }

  .menu__item_big {
    margin-bottom: 5px;
  }

  .menu__link {
    font-size: 16pt;
    line-height: 16px;
  }

  .menu__link_small {
    font-size: 12pt;
    line-height: 12px;
  }

  .menu__contact__link {
    font-size: 12pt;
  }

  .menu__contact__button {
    width: 130px;
    font-size: 10pt;
  }
}

@media (max-width: 480px) {
  .nav__burger {
    width: 20px;
    height: 20px;
    padding: 20px 0;
  }

  .nav__menu {
    padding: 0px;
    width: 35%;
    height: 500px;

    z-index: 1;
    position: relative;
  }

  .menu__list {
    padding-top: 20px;

    padding-left: 10px;
    gap: 10px;
  }

  .menu__item {
    padding: 0px;
  }

  .menu__link {
    font-size: 12pt;
    line-height: 12px;
  }

  .menu__link_small {
    font-size: 10pt;
    line-height: 10px;
  }

  .menu__contact {
    padding-left: 10px;
  }

  .menu__contact__link {
    font-size: 8pt;
  }

  .menu__contact__button {
    border-radius: 2px;
    width: 100px;
    font-size: 8pt;
    padding: 0px 5px;
  }
}
