.header {
  background-image: url(/public/img/headerback.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 1210px;
  position: relative;
  max-width: 100%;
}

.header__logo {
  text-decoration: none;
}

.header__logo__img {
  width: 570px;
  position: absolute;
  right: 150px;
  bottom: 50px;
}

.header__links__box {
  padding-top: 30px;
  display: flex;
  justify-content: end;
  margin-right: 150px;
  gap: 65px;
}

.header__links {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 10px;
}

.header__link {
  cursor: pointer;
  text-decoration: none;
  color: rgb(200, 200, 200);
  font-size: 21pt;
  font-family: 'bahnschrift';
}

.header__link:hover {
  color: rgb(99, 99, 99);
}

.header__link_small {
  font-size: 15pt;
  text-decoration: none;
  color: rgb(200, 200, 200);
  font-family: 'bahnschrift';
}

.header__content {
  margin-right: 350px;
  margin-left: 350px;
}

.header__content__tittle {
  display: flex;
  align-items: baseline;
  color: rgb(200, 200, 200);
  gap: 10px;
  padding-top: 150px;
  padding-bottom: 30px;
}

.header__content__tittle__text {
  font-size: 115pt;
  line-height: 0px;
}

.header__content__tittle__text_small {
  font-size: 48pt;
}

.header__gorline {
  border-top: 5px solid #e5e5e5;
  margin-bottom: 20px;
  margin-top: 20px;
}

.header__content__box {
  display: flex;
  gap: 40px;
}

.header__content__item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 25%;
  text-align: left;
}

.header__content__item__tittle {
  color: rgb(200, 200, 200);
  font-size: 66pt;
}

.header__content__item__text {
  color: rgb(200, 200, 200);
  font-size: 33pt;
  width: 60%;
}

.header__vertline {
  border-left: 5px solid #e5e5e5;
}

@media (max-width: 1920px) {
  .header {
    height: 700px;
  }

  .header__content {
    margin-right: 250px;
    margin-left: 250px;
  }

  .header__content__tittle__text {
    font-size: 80pt;
    line-height: 0px;
  }

  .header__content__tittle__text_small {
    font-size: 30pt;
  }

  .header__gorline {
    border-top: 2px solid #e5e5e5;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .header__content__box {
    display: flex;
    gap: 20px;
  }

  .header__content__item__tittle {
    font-size: 30pt;
  }

  .header__content__item__text {
    font-size: 15pt;
  }
}

@media (max-width: 480px) {
  .header {
    height: 500px;
    z-index: 2;
  }

  .header__logo__img {
    width: 300px;
    right: 20px;
    bottom: 20px;
  }

  .header__links__box {
    padding-top: 10px;
    margin-right: 20px;
    gap: 20px;
  }

  .header__link {
    font-size: 12pt;
  }

  .header__link_small {
    font-size: 10pt;
  }

  .header__content {
    margin-right: 20px;
    margin-left: 20px;
  }

  .header__content__tittle {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .header__content__tittle__text {
    font-size: 25pt;
    line-height: 0px;
  }

  .header__content__tittle__text_small {
    font-size: 15pt;
  }

  .header__gorline {
    border-top: 1px solid #e5e5e5;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .header__content__box {
    display: flex;
    gap: 10px;
  }

  .header__content__item__tittle {
    font-size: 15pt;
  }

  .header__content__item__text {
    font-size: 7pt;
  }

  .header__vertline {
    border-left: 1px solid #e5e5e5;
  }
}
